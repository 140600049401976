.font-inter-regular {
  font-family: Inter-Regular;
}

.font-inter-medium {
  font-family: Inter-Medium;
}

.font-inter-light {
  font-family: Inter-Light;
}

.font-inter-bold {
  font-family: Inter-Bold;
}

.font-inter-thin {
  font-family: Inter-Thin;
}

.font-inter-semibold {
  font-family: Inter-SemiBold;
}

@font-face {
  font-family: 'Inter-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Inter-Regular'), url('../fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Inter-Medium'), url('../fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Light';
  font-style: normal;
  font-weight: normal;
  src: local('Inter-Light'), url('../fonts/Inter-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Inter-Bold'), url('../fonts/Inter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Inter-Thin'), url('../fonts/Inter-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Inter-Thin'), url('../fonts/Inter-SemiBold.ttf') format('truetype');
}

.tooltip {
  border-radius: 7px !important;
  font-weight: normal !important;
  font-size: 11px !important;
  max-width: 300px !important;
  opacity: 1 !important;
}

.zoom {
  padding: 0px;
  transition: transform 0.2s;
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.09);
}

.pagination {
  margin: 15px 10px 10px 10px;
  display: flex;
  list-style: none;
  outline: none;
  font-size: 12px;
}

.pagination > .active > a {
  background-color: #5e72e4;
  color: #fff;
  font-size: 12px;
}
.pagination > li > a {
  border: 0.5px solid #e9ecef;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  border-color: transparent;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #696868;
}
.pagination > li > a:hover {
  background-color: '#373981' !important;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.previous_page {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  font-size: 12px;
  color: #696868;
}
.previous_page:hover {
  background-color: #373981 !important;
  color: white !important;
}
.next_page {
  font-size: 12px;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  color: #5e72e4 !important;
}
.next_page:hover {
  background-color: #373981 !important;
  color: white !important;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  display: none;
}

html {
  scrollbar-width: none;
}

/* Toggle A */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #fff;
}

/* Toggle B */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #fff;
}
